import React from 'react';

function Footer() {
  return (
    <footer>
      <p>&copy; 2024 Om Synthesis Studios. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
